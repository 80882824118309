html {
	background-color: rgb(51, 51, 51);
	color:rgb(158, 149, 149);
	font-family: 'Questrial', sans-serif;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
::selection {
	background:rgb(81, 81, 81);
}
::-moz-selection {
	background:rgb(81, 81, 81);
}
::-webkit-scrollbar {
	display: none;
}

/* Navbar CSS */
.Navbar {
	overflow: hidden;
	width: 100%;
	top: 0;
	left: 0;

	display: flex;
}
.navbarMain {
	font-size: 26px;
	width: 20%;
	height: 10%;
	text-align: left;
	flex: 1;

	margin-top: 1%;
	margin-left: 20%;

	cursor: default;
}
.navbarLink {
	color: rgb(158, 149, 149);
	text-decoration: none;
	position: inherit;
	padding-right: 2%;
	cursor: default;
}
.navbarLink:hover {
	color: teal;
}
.navbarRight {
	font-size: 26px;
	width: 80%;
	height: 10%;
	text-align: right;
	flex: 2;

	margin-top: 1%;
	margin-right: 20%;
}
@media screen and (min-width: 0px) and (max-width: 600px) {
	.navbarRight a:not(:last-child) {
		display: none;
	}
	.navbarRight a.icon {
		float: right;
		display: block;
		cursor: default;
		color: rgb(158, 149, 149);
		text-decoration: none;
	}
	.navbarRight a.icon:hover {
		color: teal;
	}
	.navbarRight.responsive {
		position: relative;
	}
	.navbarRight.responsive .icon {
		position: absolute;
		right: 0;
		top: 0;
	}
	.navbarRight.responsive a:not(:last-child) {
		float: none;
		display: block;
		text-align: left;
	}
	.navbarRight.responsive a:first-child {
		margin-top: 50%;
	}
}

@media screen and (min-width: 601px) {
	.navbarRight a:last-child {
		display: none;
	}
}
/*  */


.mainContent {
	font-size: 32px;
	margin-top: 10%;
  	margin-left: 20%;
	margin-right: 20%;
	
	overflow-x: auto;
}
.heading {
	font-size: 48px;
	font-weight: 750;
	padding-bottom: 1%;
}
.emphasis {
	color: teal;
}
.icons {
	margin-left: 1%;
}
.newCategory {
	margin-top: 25%;
}
.gallery {
  padding-bottom: 35%;
}
.floatIcon {
	font-size: 64px;
	bottom: 0;
	margin-bottom: 2%;
	margin-left: 1%;
	position: fixed;
}
.aboutMeContain {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.aboutMeRight {
	margin-left: 5%;
}